export enum ComponentName {
    DRIVER_SETUP = 'driverSetup',
    DRIVER_ANALYSIS = 'driverAnalysis',
    DRIVER_REPORTS = 'driverReports',
    MMM = 'mmm',
    LOGIN = 'login',
    ADMIN = 'admin',
    MENU = 'menu',
    CALLBACK = 'callback',
    INGEN_CONNECT = 'ingen-connect',
    ADMIN_SIDEBAR = 'adminSidebar',
    MY_PROFILE = 'myprofile',
    PUBLISHED_DASH = 'published-dash',
    PAGE_NOT_FOUND = 'page-not-found',
    SEARCH = 'search',
    CLIENT_DATA = 'clientData',
    INGEN_DATA = 'ingen-data',
    INGEN_CUSTOMER = 'ingenCustomer',
    TRACKER = 'tracker',
    INGEN_OPTIM = 'ingenOptim',
    ID = 'id',
    UPLOAD = 'upload',
    CONNECTIONS = 'connections',
    PIPELINES='pipelines',
    PROJECT_DATA = 'project-data',
    ASSOCIATED_DATA = 'associated-data',
    FILE_ID = 'fileId',
    FORMAT = 'format',
    PROJECT = 'project',
    QA = 'qa',
    ALL_UPLOADS = 'all-uploads',
    TEST_AND_CONTROL = 'testAndControl',
    SETUP = 'setup',
    NEW_SETUP = 'newSetup',
    ANALYSIS = 'analysis',
    ROI = 'roi',
    REPORTS = 'reports',
    BUDGET_OPTIMIZATION='budget-optimization',
    DRIVERS = 'drivers',
    TRACKER_DASHBOARD = 'trackerDashboard',
    MY_CHARTS = 'myCharts',
    CREATE_CHART = 'createChart',
    EDIT_CHART = 'editChart',
    SHARED_DASHBOARDS = 'sharedDashboards',
    CLIENT_REGISTRATION = 'client-registration',
    EDIT_PROJECT = 'edit-project',
    PROJECT_SUMMARY = 'project-summary',
    QA_ANSWERS = 'qa-answers',
    GRANULAR_LEVEL = 'granular-level',
    A_B_TEST = 'a-bTesting',
    AB_SETUP = 'a-bSetup',
    AB_CONTROL = 'a-bControlSelection',
    AB_ANALYSIS = 'a-bAnalysis',
    AB_REPORTS = 'a-bReports'

}