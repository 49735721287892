import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.css']
})
export class DeleteConfirmationModalComponent implements OnInit {
  errorMessage: string;
  constructor(public dialogRef: MatDialogRef<DeleteConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { console.log(data);}
    description:string='';
    name:string='';
    className:string[]
    
    
    
  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onProceed(){
    if (this.data.action === 'alert_delete') {
      this.dialogRef.close({ action: this.data.action, description: this.description,'name':this.name,'className': this.className });
    } else if (this.data.action === 'alert_delete_admin') {
      this.dialogRef.close({'action':this.data.action,'description':this.description,'name':this.name,'className': this.className});
    } else {
      this.dialogRef.close({'action':this.data.action,'description':this.description,'name':this.name,'className': this.className});
    }
    
  }
  validateLength(value:string) {
    if(value.length > 128) {
      this.errorMessage = "Reason connot exceeds 128 characters."
    }else {
      this.errorMessage = ''
    }
  }
}
