// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // PYTHON_API_ENDPOINT : 'https://papi-tst.ingenspark.com/',
  // LOGIN_API_ENDPOINT : 'https://japi-tst.ingenspark.com/',
  PYTHON_API_ENDPOINT : 'https://papidev.ingenspark.com/',
  LOGIN_API_ENDPOINT : 'https://japidev.ingenspark.com/',
  CLIENT_MANAGEMENT_ENDPOINT : 'https://jcmapidev.ingenspark.com/',
  aws_access_key_id: 'AKIAT4R3SOPSFO3CUAPP',
  aws_secret_access_key: '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',
  aws_region: 'us-east-2',
  aws_region_us_east_one: 'us-east-1',
  bucket_name: 'ingenworksstorage',
  bucket_name_for_reports: 'ingenworksfiles',
  sso_api_username: '1ita9m0r12g9ao13nbicfmecll',
  sso_api_pwd: '105mf93hg7qchgahurb2lpej8v2iglq0nvkt3u4rrr4lslj0ia66',

  loginURL: 'https://ingen.auth.us-east-1.amazoncognito.com/login?client_id=1ita9m0r12g9ao13nbicfmecll&response_type=code&scope=openid+profile&redirect_uri=https://ingen.ingenspark.com/callback',

  redirectURL: 'https://ingen.ingenspark.com/callback',

  cognitoTokenURL: 'https://ingen.auth.us-east-1.amazoncognito.com/oauth2/token',

  logout: 'https://ingen.auth.us-east-1.amazoncognito.com/logout?' +
          'client_id=1ita9m0r12g9ao13nbicfmecll&' +
          'logout_uri=https://ingen.ingenspark.com/login',
  devServerUrl: 'https://ingendev01.ingenspark.com/login'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
